'use strict';

angular.module('cordovaInit', [])

var CordovaInit = function () {

    var onDeviceReady = function () {
        receivedEvent('deviceready');
    };

    var receivedEvent = function () {
        angular.bootstrap($('body'), ['app']);
    };

    this.bindEvents = function () {
        document.addEventListener('deviceready', onDeviceReady, false);
    };

    //If cordova is present, wait for it to initialize, otherwise just try to
    //bootstrap the application.
    if (window.cordova != undefined) {
        this.bindEvents();
    } else {
        receivedEvent('manual');
    }
};

$(function () {
    new CordovaInit();
});



//********************************
//********************************
// PLUGINS NECESARIOS al crear el proyecto Cordova


//cordova plugin add cordova-plugin-device ( o cordova plugin add org.apache.cordova.device)
//cordova plugin add cordova-plugin-file
//cordova plugin add cordova-plugin-camera


//********************************
//********************************