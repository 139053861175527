angular.module('usuarios', [])
       .controller('usuarioController', ['$scope', '$routeParams', '$location', 'usuariosService', function ($scope, $routeParams, $location, usuariosService) {
           usuariosService.getConfig()
                             .then(function (response) {
                                 var data = response.data;
                                 $scope.usuario = data;
                             });
          
           $scope.editarUsuario = function () {
               usuariosService.put($scope.usuario).then(function (response) {
                   var data = response.data;
                   alert(data);
                
               }).catch(function (response) {
                   $scope.error = usuariosService.obtenerPrimerErrorModelState(response.data);
                   alert($scope.error);
               });
           }

           $scope.cambiarPassword = function () {
                $location.path("/usuario/password/" + $scope.usuario.Id);
           }
       }]);
