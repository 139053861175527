angular.module('usuarios')
       .controller('usuarioPasswordController', ['$scope', '$routeParams', '$location', 'usuariosService', function ($scope, $routeParams, $location, usuariosService) {
           $scope.password = "";
           $scope.passwordNew = "";
           $scope.passwordConfirm = "";

           $scope.passwordsUser = {
               Id: $routeParams.id,
               Password: "",
               PasswordNew: ""
           };

           $scope.cambiarPassword = function () {
               $scope.passwordsUser.Password = $scope.password;
               $scope.passwordsUser.PasswordNew = $scope.passwordNew;
               usuariosService.putPwd($scope.passwordsUser).then(function (response) {
                   var data = response.data;
                   alert(data);
                   $location.path("/usuario/" + $scope.usuario.Id);
               }).catch(function (response) {
                   $scope.error = usuariosService.obtenerPrimerErrorModelState(response.data);
                   alert($scope.error);
               });
           }
       }])
.directive('ngMatch', ['$parse', function ($parse) {

    var directive = {
        link: link,
        restrict: 'A',
        require: '?ngModel'
    };
    return directive;

    function link(scope, elem, attrs, ctrl) {
        // if ngModel is not defined, we don't need to do anything
        if (!ctrl) return;
        if (!attrs['ngMatch']) return;

        var firstPassword = $parse(attrs['ngMatch']);

        var validator = function (value) {
            var temp = firstPassword(scope),
            v = value === temp;
            ctrl.$setValidity('match', v);
            return value;
        }

        ctrl.$parsers.unshift(validator);
        ctrl.$formatters.push(validator);
        attrs.$observe('ngMatch', function () {
            validator(ctrl.$viewValue);
        });

    }
}]);
