angular.module('materiales', [])
    .controller('materialesController', ['$scope', '$rootScope', '$routeParams', '$location', 'authenticationInfo', 'materialesService', 'dataPersistService', 'onlineStatus', 'storage', 'Utilidades', '$cordovaFile', '$cordovaFileTransfer', function ($scope, $rootScope, $routeParams, $location, authenticationInfo, materialesService, dataPersistService, onlineStatus, storage, Utilidades, $cordovaFile, $cordovaFileTransfer) {
           $scope.authentication = authenticationInfo;
           $scope.idSupervision = ($routeParams.id == null) ? 0 : $routeParams.id;
           $scope.materiales = dataPersistService.getMateriales();
           $scope.IsOnLine = onlineStatus.isOnline();
           $scope.IsMobile = Utilidades.isMobileDevice();

           $scope.dateOptions = Utilidades.getDatePickerOptions();

          var titulo = dataPersistService.getTextoCabecera();
           if (titulo != null && titulo != "" && titulo.length > 0) {
             $rootScope.title = titulo;
           }

           if ($scope.IsOnLine) {
               if ($scope.materiales == null) {
                   if ($routeParams.id > 0) {
                       materialesService.getMateriales($routeParams.id)
                                         .then(function (response) {
                                            var data = response.data;
                                             $scope.materiales = data;
                                         });
                   }
               }
           }

           $scope.$on('LastRepeaterElement', function() {
              if (!$scope.added) {
                $('.panel .collapse').collapse("hide");
              }
              $scope.added = false;
            });
            
           $scope.inputID = "";
           $scope.uploadFile = function (input) {
               if (input.files && input.files[0]) {
                   var reader = new FileReader();
                   $scope.inputID = input.id;
                   reader.onload = $scope.imageIsLoaded;
                   $scope.imageName = input.files[0].name;
                   reader.readAsDataURL(input.files[0]);
               }
           };
           $scope.imageIsLoaded = function (e) {
               $scope.$apply(function () {
                   var index = parseInt($scope.inputID.substring('photo-upload'.length));
                   $('#imagenSeleccionada' + index).attr('src', e.target.result);

                   $scope.materiales[index].Imagen = e.target.result.replace(/data:image\/jpeg;base64,/g, '');
                   $scope.materiales[index].NombreImagen = $scope.imageName;
                   $scope.imageName = null;
               });
           }

           //Menú superior
           $scope.DatosSupervisionCargar = function () {
               dataPersistService.saveMateriales($scope.materiales);
               $location.path("/supervisiones/" + $scope.idSupervision);
           }
           $scope.ClimatologiaCargar = function () {
               dataPersistService.saveMateriales($scope.materiales);
               $location.path("/climatologias/" + $scope.idSupervision);
           }
           $scope.PreparacionSuperficieCargar = function () {
               dataPersistService.saveMateriales($scope.materiales);
               $location.path("/preparacionessuperficie/" + $scope.idSupervision);
           }
           $scope.PuestasObraCargar = function () {
               dataPersistService.saveMateriales($scope.materiales);
               $location.path("/puestasobra/" + $scope.idSupervision);
           }
           $scope.TomasMuestrasCargar = function () {
               dataPersistService.saveMateriales($scope.materiales);
               $location.path("/tomasmuestras/" + $scope.idSupervision);
           }
           //Fin menú superior

           $scope.Informe = function () {
               materialesService.informeSupervision($scope.idSupervision)
                .then(function (response) {
                    var data = response.data;
                    try {
                        var file = new Blob([data], { type: 'application/pdf' });
                    }
                    catch (e) {
                        // TypeError old chrome and FF
                        window.BlobBuilder = window.BlobBuilder ||
                                             window.WebKitBlobBuilder ||
                                             window.MozBlobBuilder ||
                                             window.MSBlobBuilder;
                        if (e.name == 'TypeError' && window.BlobBuilder) {
                            var bb = new BlobBuilder();
                            bb.append([data]);
                            var file = bb.getBlob("application/pdf");
                        }
                        else if (e.name == "InvalidStateError") {
                            // InvalidStateError (tested on FF13 WinXP)
                            var file = new Blob([data], { type: "application/pdf" });
                        }
                        else {
                            // blob constructor unsupported entirely   
                        }
                    }

                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(file);
                    }
                    else {
                        var fileURL = URL.createObjectURL(file);
                        if ($scope.IsMobile) {
                            alert('Informe generado correctamente');
                            window.open(fileURL, "_blank", "location=yes");
                        } else {
                            window.open(fileURL, "_blank", "location=yes");
                        }
                    }
                }).catch(function (response) {
                    alert('Error al generar el informe');
                });
           }

           $scope.NuevoMaterial = function () {
                $scope.added = true;
                if ($scope.materiales == null) {
                    $scope.materiales = [];
                } 
                var material = {
                    Id: 0 - $scope.materiales.length,
                    NumeroMaterial: $scope.materiales.length+1,
                    Matricula: null,
                    PesoVacio: null,
                    PesoCargado: null,
                    NombreMaterial: '',
                    Procedencia: null,
                    Fecha: null,
                    Hora: null,
                    MarcadoCE: null,
                    Observaciones: null,
                    Imagen: null,
                    NombreImagen: null
                }
                $scope.materiales.push(material);
           }
           $scope.CopiarMaterialAnterior = function () {
               $scope.added = true;
               var materialAnterior = $scope.materiales[$scope.materiales.length - 1];
               var material = {
                   Id: 0 - $scope.materiales.length,
                   NumeroMaterial: $scope.materiales.length + 1,
                   Matricula: materialAnterior.Matricula,
                   PesoVacio: materialAnterior.PesoVacio,
                   PesoCargado: materialAnterior.PesoCargado,
                   NombreMaterial: materialAnterior.NombreMaterial,
                   Procedencia: materialAnterior.Procedencia,
                   Fecha: materialAnterior.Fecha,
                   Hora: materialAnterior.Hora,
                   MarcadoCE: materialAnterior.MarcadoCE,
                   Observaciones: null,
                   Imagen: null,
                   NombreImagen: null
               }
               $scope.materiales.push(material);
           }
           $scope.EliminarMaterial = function (materialIndex) {
               $scope.materiales.splice($scope.materiales.indexOf(materialIndex), 1);
           }

           $scope.Guardar = function () {
               for (var i = 0; i < $scope.materiales.length; i++) {
                   $scope.materiales[i].SupervisionId = $scope.idSupervision;
               }
               dataPersistService.saveMateriales($scope.materiales);
               var supervisionCompleta = dataPersistService.getSupervisionCompleta();
               if ($scope.idSupervision < 1) {
                   if ($scope.IsOnLine) {
                       materialesService.post(supervisionCompleta).then(function (response) {
                            var data = response.data;
                           $scope.idSupervision = data;
                           /*for (var i = 0; i < $scope.materiales.length; i++) {
                               $scope.materiales[i].SupervisionId = data;
                           }*/
                           alert("Supervisión creada correctamente.");
                           dataPersistService.saveMateriales(null);
                           materialesService.getMateriales($scope.idSupervision)
                                             .then(function (response) {
                                                var data = response.data;
                                                 $scope.materiales = data;
                                                 //upload($scope.picFile);
                                             });
                       }).catch(function (response) {
                           $scope.error = materialesService.obtenerPrimerErrorModelState(response.data);
                           alert($scope.error);
                       });
                   } else {
                       supervisionCompleta.DatosSupervision.Id = $scope.idSupervision;
                       try {
                           storage.set("supervision" + $scope.idSupervision, supervisionCompleta);
                           alert("Supervisión creada correctamente.");
                       } catch (error) {
                           alert("Error al crear la supervisión.");
                       }
                   }
               } else {
                   if ($scope.IsOnLine) {
                       materialesService.put(supervisionCompleta).then(function (response) {
                           var data = response.data;
                           alert(data);
                           dataPersistService.saveMateriales(null);
                           materialesService.getCodigoSupervision($scope.idSupervision)
                                 .then(function (response) {
                                    var data = response.data;
                                     dataPersistService.setTextoCabecera("<span class='navbar-tit'>" + data[0] + "</span><br><span class='navbar-subtit'>" + data[1] + "</span>");
                                     $rootScope.title = dataPersistService.getTextoCabecera();
                                 });
                       }).catch(function (response) {
                           $scope.error = materialesService.obtenerPrimerErrorModelState(response.data);
                           alert($scope.error);
                       });
                   } else {
                       alert("Error de conexión.");
                       /*try {
                           storage.set("supervision" + $scope.idSupervision, supervisionCompleta);
                           alert("Supervisión guardada correctamente.");
                       } catch (error) {
                           alert("Error al guardar la supervisión.");
                       }*/
                   }
               }
           }

           if ($scope.IsOnLine) {
               //Funcion para mostrar u ocultar el boton de guardar
               $scope.supervision = dataPersistService.getDatosSupervision();
               $scope.MostrarBotonGuardar = function () {
                   if ($scope.authentication.rol == "Consultor") {
                       if ($scope.supervision.Username.toUpperCase() != $scope.authentication.userName.toUpperCase()) {
                           return false;
                       }
                   }
                   return true;
               }
           } else {
               $scope.MostrarBotonGuardar = function () {
                   return true;
               }
           }

           $scope.Siguiente = function () {
               dataPersistService.saveMateriales($scope.materiales);
               $location.path("/puestasobra/" + $scope.idSupervision);
           }

           $scope.Anterior = function () {
               dataPersistService.saveMateriales($scope.materiales);
               $location.path("/preparacionessuperficie/" + $scope.idSupervision);
           }
    }]);