angular
    .module('usuarios')
    .factory('usuariosService', ['$http', '$q', '__env', function usuariosService($http, $q, __env) {
        var serviceUrl = __env.apiUrl;
        var usuariosUrl = 'usuarios';

        function getConfig() {
            return $http.get(serviceUrl + usuariosUrl + '/config/' );
        }

        function put(usuario) {
            return $http.put(serviceUrl + usuariosUrl + '/editar/config', usuario);
        }

        function putPwd(passwordsUser) {
            return $http.put(serviceUrl + usuariosUrl + '/editar/password', passwordsUser);
        }

        function obtenerPrimerErrorModelState(response) {
            var errores = [];
            for (var key in response.ModelState) {
                for (var i = 0; i < response.ModelState[key].length; i++) {
                    errores.push(response.ModelState[key][i]);
                }
            }
            return errores[0];
        }

        return {
            getConfig:getConfig,
            put: put,
            putPwd: putPwd,
            obtenerPrimerErrorModelState: obtenerPrimerErrorModelState,
        }
    }]);