angular
    .module('supervisiones')
    .factory('supervisionesService', ['$http', '$q', '__env', function supervisionesService($http, $q, __env) {
        var serviceUrl = __env.apiUrl;
        var supervisionesUrl = 'supervisiones';

        function getSupervisiones() {
            return $http.get(serviceUrl + supervisionesUrl);
        }

        function getObrasSupervisiones() {
            return $http.get(serviceUrl + supervisionesUrl +'/obras');
        }

        function getSupervisoresSupervisiones() {
            return $http.get(serviceUrl + supervisionesUrl + '/supervisores');
        }

        function getSupervision(id) {
            return $http.get(serviceUrl + supervisionesUrl + '/' + id);
        }

        function getCodigoSupervision(id) {
            return $http.get(serviceUrl + supervisionesUrl + '/codigo/' + id);
        }

        function informeSupervision(id) {
            return $http.get(serviceUrl + supervisionesUrl + '/informe/' + id, { responseType: 'arraybuffer' });
        }

        function post(supervision) {
            //return $http.post(serviceUrl + supervisionesUrl + '/registrar/', supervision);
            return $http({
                method: 'POST',
                url: serviceUrl + 'supervisiones/registrar',
                data: supervision,
                headers: { 'Content-Type': 'multipart/form-data' }
            })
        }
        function put(supervision) {
            //return $http.put(serviceUrl + supervisionesUrl + '/editar', supervision);
            return $http({
                method: 'PUT',
                url: serviceUrl + 'supervisiones/editar',
                data: supervision,
                //headers: { 'Content-Type': 'application/json'}
                headers: { 'Content-Type': 'multipart/form-data' }
            })
        }

        function obtenerPrimerErrorModelState(response) {
            var errores = [];
            for (var key in response.ModelState) {
                for (var i = 0; i < response.ModelState[key].length; i++) {
                    errores.push(response.ModelState[key][i]);
                }
            }
            return errores[0];
        }

        return {
            getSupervisiones: getSupervisiones,
            getSupervision: getSupervision,
            getCodigoSupervision: getCodigoSupervision,
            informeSupervision: informeSupervision,
            getObrasSupervisiones: getObrasSupervisiones,
            getSupervisoresSupervisiones: getSupervisoresSupervisiones,
            post: post,
            put: put,
            obtenerPrimerErrorModelState: obtenerPrimerErrorModelState,
        }
    }]);