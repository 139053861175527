angular.module('climatologias', [])
    .controller('climatologiaController', ['$scope', '$rootScope', '$routeParams', '$location', 'authenticationInfo', 'climatologiasService', 'Utilidades', 'dataPersistService', 'onlineStatus', 'storage', function ($scope, $rootScope, $routeParams, $location, authenticationInfo, climatologiasService, Utilidades, dataPersistService, onlineStatus, storage) {
        $scope.authentication = authenticationInfo;
        $scope.idSupervision = ($routeParams.id == null) ? 0 : $routeParams.id;
        $scope.climatologia = dataPersistService.getClimatologia();
        $scope.IsOnLine = onlineStatus.isOnline();
        $scope.IsMobile = Utilidades.isMobileDevice();

        $scope.dateOptions = Utilidades.getDatePickerOptions();

        var titulo = dataPersistService.getTextoCabecera();
        if (titulo != null && titulo != "" && titulo.length > 0) {
            $rootScope.title = titulo;
        }

        if ($scope.IsOnLine) {
            if ($scope.climatologia == null) {
                if ($routeParams.id > 0) {
                    climatologiasService.getClimatologiaPorSupervision($routeParams.id)
                        .then(function (response) {
                            var data = response.data;
                            $scope.climatologia = data;
                        });
                }
            }
        }

        //Menú superior
        $scope.DatosSupervisionCargar = function () {
            dataPersistService.saveClimatologia($scope.climatologia);
            $location.path("/supervisiones/" + $scope.idSupervision);
        }
        $scope.PreparacionSuperficieCargar = function () {
            dataPersistService.saveClimatologia($scope.climatologia);
            $location.path("/preparacionessuperficie/" + $scope.idSupervision);
        }
        $scope.MaterialesCargar = function () {
            dataPersistService.saveClimatologia($scope.climatologia);
            $location.path("/materiales/" + $scope.idSupervision);
        }
        $scope.PuestasObraCargar = function () {
            dataPersistService.saveClimatologia($scope.climatologia);
            $location.path("/puestasobra/" + $scope.idSupervision);
        }
        $scope.TomasMuestrasCargar = function () {
            dataPersistService.saveClimatologia($scope.climatologia);
            $location.path("/tomasmuestras/" + $scope.idSupervision);
        }
        //Fin menú superior

        $scope.Informe = function () {
            climatologiasService.informeSupervision($scope.idSupervision)
                .then(function (response) {
                    var data = response.data;
                    try {
                        var file = new Blob([data], { type: 'application/pdf' });
                    }
                    catch (e) {
                        // TypeError old chrome and FF
                        window.BlobBuilder = window.BlobBuilder ||
                            window.WebKitBlobBuilder ||
                            window.MozBlobBuilder ||
                            window.MSBlobBuilder;
                        if (e.name == 'TypeError' && window.BlobBuilder) {
                            var bb = new BlobBuilder();
                            bb.append([data]);
                            var file = bb.getBlob("application/pdf");
                        }
                        else if (e.name == "InvalidStateError") {
                            // InvalidStateError (tested on FF13 WinXP)
                            var file = new Blob([data], { type: "application/pdf" });
                        }
                        else {
                            // blob constructor unsupported entirely   
                        }
                    }

                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(file);
                    }
                    else {
                        var fileURL = URL.createObjectURL(file);
                        if ($scope.IsMobile) {
                            alert('Informe generado correctamente');
                            window.open(fileURL, "_blank", "location=yes");
                        } else {
                            window.open(fileURL, "_blank", "location=yes");
                        }
                    }
                }).catch(function (response) {
                    alert('Error al generar el informe');
                });
        }

        $scope.Guardar = function () {
            $scope.climatologia.SupervisionId = $scope.idSupervision;
            dataPersistService.saveClimatologia($scope.climatologia);
            var supervisionCompleta = dataPersistService.getSupervisionCompleta();
            if ($scope.idSupervision < 1) {
                if ($scope.IsOnLine) {
                    climatologiasService.post(supervisionCompleta).then(function (response) {
                        var data = response.data;
                        $scope.idSupervision = data;
                        $scope.climatologia.SupervisionId = data;
                        alert("Supervisión creada correctamente.");
                        dataPersistService.saveClimatologia(null);
                        climatologiasService.getClimatologiaPorSupervision($scope.idSupervision)
                            .then(function (response) {
                                var data = response.data;
                                $scope.climatologia = data;
                            });
                    }).catch(function (response) {
                        $scope.error = climatologiasService.obtenerPrimerErrorModelState(response.data);
                        alert($scope.error);
                    });
                } else {
                    supervisionCompleta.DatosSupervision.Id = $scope.idSupervision;
                    try {
                        storage.set("supervision" + $scope.idSupervision, supervisionCompleta);
                        alert("Supervisión creada correctamente.");
                    } catch (error) {
                        alert("Error al crear la supervisión.");
                    }
                }
            } else {
                if ($scope.IsOnLine) {
                    climatologiasService.put(supervisionCompleta).then(function (response) {
                        var data = response.data;
                        alert(data);
                        dataPersistService.saveClimatologia(null);
                        climatologiasService.getCodigoSupervision($scope.idSupervision)
                            .then(function (response) {
                                var data = response.data;
                                dataPersistService.setTextoCabecera("<span class='navbar-tit'>" + data[0] + "</span><br><span class='navbar-subtit'>" + data[1] + "</span>");
                                $rootScope.title = dataPersistService.getTextoCabecera();
                            });
                    }).catch(function (response) {
                        $scope.error = climatologiasService.obtenerPrimerErrorModelState(response.data);
                        alert($scope.error);
                    });
                } else {
                    alert("Error de conexión.");
                    /*try {
                        storage.set("supervision" + $scope.idSupervision, supervisionCompleta);
                        alert("Supervisión guardada correctamente.");
                    } catch (error) {
                        alert("Error al guardar la supervisión.");
                    }*/
                }
            }
        }

        if ($scope.IsOnLine) {
            //Funcion para mostrar u ocultar el boton de guardar
            $scope.supervision = dataPersistService.getDatosSupervision();
            $scope.MostrarBotonGuardar = function () {
                if ($scope.authentication.rol == "Consultor") {
                    if ($scope.supervision.Username.toUpperCase() != $scope.authentication.userName.toUpperCase()) {
                        return false;
                    }
                }
                return true;
            }
        } else {
            $scope.MostrarBotonGuardar = function () {
                return true;
            }
        }

        $scope.Siguiente = function () {
            dataPersistService.saveClimatologia($scope.climatologia);
            $location.path("/preparacionessuperficie/" + $scope.idSupervision);
        }

        $scope.Anterior = function () {
            dataPersistService.saveClimatologia($scope.climatologia);
            $location.path("/supervisiones/" + $scope.idSupervision);
        }
    }]);
