angular.module('alertasAdmin')
    .factory('alertasAdminService', ['$http', '$q', '__env', function alertasAdminService($http, $q, __env) {
        var serviceUrl = __env.apiUrl;
        var supervisionesUrl = 'supervisiones';

        function getDatosObras() {
            return $http.get(serviceUrl + supervisionesUrl + "/obras/alertas");
        }

        function getUsuariosObra(obra) {
            obra = obra.replace(".","__");
            return $http.get(serviceUrl + "usuarios/alertas/" + obra);
        }

        function put(usuarios) {
            return $http.put(serviceUrl + 'usuarios/alertas/put', usuarios);
        }

        function obtenerPrimerErrorModelState(response) {
            var errores = [];
            for (var key in response.ModelState) {
                for (var i = 0; i < response.ModelState[key].length; i++) {
                    errores.push(response.ModelState[key][i]);
                }
            }
            return errores[0];
        }

        return {
            getDatosObras: getDatosObras,
            getUsuariosObra: getUsuariosObra,
            put: put,
            obtenerPrimerErrorModelState: obtenerPrimerErrorModelState
        }
    }]);

