var env = {};
if (window) {
    env = JSON.parse(JSON.stringify(window.__env));
}
angular.module('app', ['ngRoute', 'ui.bootstrap', 'auth', 'login', 'usuariosAdmin', 'alertasAdmin', 'usuarios', 'supervisiones', 'climatologias', 'preparacionesSuperficie', 'materiales', 'puestaEnObra', 'tomaDeMuestras', 'home', 'angularLocalStorage', 'ngFileUpload', 'ngCordova'])
    //.constant('serviceUrl', 'http://localhost:65463/') 
    //.constant('serviceUrl', 'http://37.187.152.56:8088/') 
    .constant('__env', env)
    .config(['$routeProvider', function ($routeProvider) {
        $routeProvider
            .when('/home', {
                templateUrl: 'app/home/home.html',
                title: 'Inicio'
            })
         
             .when('/admin/usuarios/nuevo', {
                 templateUrl: 'app/admin/usuarios/nuevoUsuarioAdmin.html',
                 title: 'Nuevo Usuario'
             })
            .when('/admin/usuarios', {
                templateUrl: 'app/admin/usuarios/usuariosAdmin.html',
                title: 'Gestión Usuarios'
            })
            .when('/admin/alertas', {
                templateUrl: 'app/admin/alertas/alertasAdmin.html',
                title: 'Gestión Alertas'
            })
            .when('/admin/alertas/usuarios/:obra', {
                templateUrl: 'app/admin/alertas/usuariosAlertasAdmin.html',
                title: 'Gestión Alertas'
            })
            .when('/usuario/config', {
                templateUrl: 'app/usuario/editarUsuario.html',
                title: 'Modificar Usuario'
            })
            .when('/usuario/password/:id', {
                templateUrl: 'app/usuario/cambiarPwd.html',
                title: 'Modificar Contraseña Usuario'
            })
            .when('/admin/usuarios/:id', {
                templateUrl: 'app/admin/usuarios/editarUsuarioAdmin.html',
                title: 'Gestión Usuarios'
            })

            .when('/supervisiones', {
                templateUrl: 'app/supervisiones/supervisiones.html',
                title: 'Supervisiones'
            })
            .when('/supervisiones/nueva', {
                templateUrl: 'app/supervisiones/supervision.html',
                title: 'Nueva Supervisión'
            })
            .when('/supervisiones/:id', {
                templateUrl: 'app/supervisiones/supervision.html',
                title: 'Supervisión'
            })

            .when('/climatologias/:id', {
                templateUrl: 'app/climatologias/climatologia.html',
                title: 'Climatología'
            })
            .when('/preparacionessuperficie/:id', {
                templateUrl: 'app/preparacionesSuperficie/preparacionSuperficie.html',
                title: 'Preparación de Superficie'
            })
            .when('/materiales/:id', {
                templateUrl: 'app/materiales/material.html',
                title: 'Materiales'
            })
            .when('/puestasobra/:id', {
                templateUrl: 'app/puestasObra/puestaObra.html',
                title: 'Puestas en Obra'
            })
            .when('/tomasmuestras/:id', {
                templateUrl: 'app/tomasMuestras/tomaMuestras.html',
                title: 'Tomas de Muestras'
            })

            .otherwise({
                redirectTo: '/home'
            });
    }])

    .config(['$httpProvider', function ($httpProvider) {
        $httpProvider.interceptors.push('authInterceptor');
    }])
    /*.config(['$compileProvider', function ($compileProvider) {
        //Si se habilita no va el fileUpload -para esta versión de Angular
        $compileProvider.debugInfoEnabled(false);
    }])*/
    .run(['$rootScope', '$location', '$route', 'authenticationService', 'authEvents', '$http',
        function ($rootScope, $location, $route, authenticationService, authEvents, $http) {
            $rootScope.$on(authEvents.login, function () {
                $location.path('/supervisiones');
            });
            $rootScope.$on(authEvents.logout, function () {
                $location.path('/home');
            });
            $rootScope.$on('$routeChangeSuccess', function() {
                $rootScope.title = $route.current.title;
            });
            document.addEventListener('deviceready', onDeviceReady, false);
            function onDeviceReady() {
                document.addEventListener('backbutton', function (e) {
                    if (($location.path() == '/home' || $location.path() == '/supervisiones')
                        && confirm('¿Desea salir de la aplicación?')) {
                        e.preventDefault();
                        navigator.app.exitApp();
                    }
                    else {
                        navigator.app.backHistory();
                    }
                }, false);
            }

        }])
.directive('ngTextoConfirmar', [
        function () {
            return {
                link: function (scope, element, attr) {
                    var msg = attr.ngTextoConfirmar || "¿Desea completar la operación?";
                    var clickAction = attr.ngClickConfirmado;
                    element.bind('click', function (event) {
                        if (window.confirm(msg)) {
                            scope.$apply(clickAction)
                        }
                    });
                }
            };
        }])

    

.factory('onlineStatus', ["$window", "$rootScope", function ($window, $rootScope) {
    var onlineStatus = {};

    onlineStatus.onLine = $window.navigator.onLine;
    //onlineStatus.onLine = false;

    onlineStatus.isOnline = function () {
        return onlineStatus.onLine;
    }

    $window.addEventListener("online", function () {
        onlineStatus.onLine = true;
        $rootScope.$digest();
    }, true);

    $window.addEventListener("offline", function () {
        onlineStatus.onLine = false;
        $rootScope.$digest();
    }, true);

    return onlineStatus;
}])
.factory('Utilidades', function () {
    var interfaz = {
        getFecha: function (value) {
            if (value != null && value.length) {
                var fechaFormateada = new Date(value);
                return formatDate(fechaFormateada, "dd/MM/yyyy");
            }
        },
        getLote: function (value) {
            if (value != null) {
                return ("000" + value).substring(("000" + value).length - 3);
            } else {
                return ("001");
            }
        },
        getDatePickerOptions: function () {
            var fechaActual = new Date();
            var fechaIni = fechaActual.getUTCFullYear() + '/' + fechaActual.getUTCDate() + '/' + (fechaActual.getUTCMonth() + 1);
            var datepickerOptions = {
                startingDay: 1,
                startDate: new Date(fechaIni)
            }
            return datepickerOptions;

        },
        isMobileDevice: function () {
            var isMobile = {
                Android: function () {
                    return navigator.userAgent.match(/Android/i);
                },
                BlackBerry: function () {
                    return navigator.userAgent.match(/BlackBerry/i);
                },
                iOS: function () {
                    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
                },
                Opera: function () {
                    return navigator.userAgent.match(/Opera Mini/i);
                },
                Windows: function () {
                    return navigator.userAgent.match(/IEMobile/i);
                },
                any: function () {
                    return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
                }
            };
            return isMobile.any();
        },
        iOSMobileDevice: function () {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        }
    }
    return interfaz;
})
.factory("Excel", function()
{
    var uri = 'data:application/vnd.ms-excel;base64,'
          , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head> <meta http-equiv="content-type" content="text/plain; charset=UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table  border="1">{table}</table></body></html>'
          , base64 = function(s) { return window.btoa(unescape(encodeURIComponent(s))) }
          , format = function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }) }  
    return {
        exportar: function (div, table, name) {
            //Si el navegador es IE
            if (navigator.msSaveBlob) {
                return navigator.msSaveBlob(new Blob([document.getElementById(div).innerHTML], {
                    type: "application/vnd.ms-excel"
                }), name + ".xls");
            }
            else {
                if (!table.nodeType) table = document.getElementById(table)
                var ctx = { worksheet: name || 'Worksheet', table: table.innerHTML }
                var link = document.createElement("a");
                link.download = name+ ".xls";
                link.href = uri + base64(format(template, ctx));
                link.click();
            }
                        
        }

    }
})
.factory('dataPersistService', function () {
    var textoCabecera = '';

    var supervision = {
            DatosSupervision: null,
            Climatologia: null,
            PreparacionSuperficie: null,
            Materiales: null,
            PuestaEnObra: null,
            TomaDeMuestras: {
                Id: null,
                SupervisionId: null,
                RiegosAdherencia: null,
                CapasRodadura: null,
                Testigos: null,
                Macrotexturas: null,
            }
    };

    return {
        getTextoCabecera: function () {
            return textoCabecera;
        },
        setTextoCabecera: function (texto) {
            textoCabecera = texto;
        },
        getSupervisionCompleta: function () {
            return supervision;
        },
        saveSupervisionCompleta: function (supervisionCompleta) {
            supervision = supervisionCompleta;
        },
        initializeSupervisionCompleta: function () {
            supervision.DatosSupervision = null;
            supervision.Climatologia = null;
            supervision.PreparacionSuperficie = null;
            supervision.Materiales = null;
            supervision.PuestaEnObra = null;
            supervision.TomaDeMuestras.Id = null;
            supervision.TomaDeMuestras.RiegosAdherencia = null;
            supervision.TomaDeMuestras.CapasRodadura = null;
            supervision.TomaDeMuestras.Testigos = null;
            supervision.TomaDeMuestras.Macrotexturas = null;
        },
        initializeTomaDeMuestras: function () {
            supervision.TomaDeMuestras.Id = null;
            supervision.TomaDeMuestras.RiegosAdherencia = null;
            supervision.TomaDeMuestras.CapasRodadura = null;
            supervision.TomaDeMuestras.Testigos = null;
            supervision.TomaDeMuestras.Macrotexturas = null;
        },
        saveDatosSupervision: function (data) {
            supervision.DatosSupervision = data;
        },
        getDatosSupervision: function () {
            return supervision.DatosSupervision;
        },
        saveClimatologia: function (data) {
            supervision.Climatologia = data;
        },
        getClimatologia: function () {
            return supervision.Climatologia;
        },
        savePreparacionSuperficie: function (data) {
            supervision.PreparacionSuperficie = data;
        },
        getPreparacionSuperficie: function () {
            return supervision.PreparacionSuperficie;
        },
        saveMateriales: function (data) {
            supervision.Materiales = data;
        },
        getMateriales: function () {
            return supervision.Materiales;
        },
        savePuestaEnObra: function (data) {
            supervision.PuestaEnObra = data;
        },
        getPuestaEnObra: function () {
            return supervision.PuestaEnObra;
        },
        saveTomaDeMuestras: function (data) {
            if (data == null) {
                supervision.TomaDeMuestras =  {
                        Id: null,
                        SupervisionId: null,
                        RiegosAdherencia: null,
                        CapasRodadura: null,
                        Testigos: null,
                        Macrotexturas: null,
                }
            } else {
                supervision.TomaDeMuestras = data;
            }
        },
        getTomaDeMuestras: function () {
            return supervision.TomaDeMuestras;
        }
    };
})
.directive('aDisabled', function () {
    return {
        compile: function (tElement, tAttrs, transclude) {
            //Disable ngClick
            tAttrs["ngClick"] = "!(" + tAttrs["aDisabled"] + ") && (" + tAttrs["ngClick"] + ")";

            //return a link function
            return function (scope, iElement, iAttrs) {
                //Disable href on click
                iElement.on("click", function (e) {
                    if (scope.$eval(iAttrs["aDisabled"])) {
                        e.preventDefault();
                    }
                });
            };
        }
    };
})
.directive('emitLastRepeaterElement', function() {
    return function(scope) {
        if (scope.$last){
            scope.$emit('LastRepeaterElement');
        }
    };
})
.filter('unsafe', ['$sce', function($sce) { return $sce.trustAsHtml; }]);
